import React, { useState } from "react";
import "./header.css";
import logo from "../../img/logo/Logo.png";
import menu from "../../img/diversos/menu_mobile.png";
import close from "../../img/diversos/x.png";

export const Header = ({ scrollToSection }) => {
  const [showMenu, setShowMenu] = useState(false);

  const handleMenu = () => {
    setShowMenu(!showMenu);
    console.log("Menu toggled");
  };

  const icon = () => {
    return <img src={showMenu ? close : menu} alt={showMenu ? "close icon" : "menu icon"} />;
  };

  return (
    <div className="Header">
      <div className="Main-header">
        <img src={logo} alt="logo" />
        <div className="Menu">
          <a href="#about"><button onClick={() => { console.log("About clicked"); }}>About</button></a>
          <a href="#services"><button onClick={() => { console.log("Services clicked"); }}>Services</button></a>
          <a href="mailto:beesimpledesign@gmail.com"><button>Contact us</button></a>
        </div>
        <div className="MenuMobile">
          <button onClick={handleMenu}>{icon()}</button>
        </div>
      </div>
      {showMenu && (
        <div className="Menuscreen">
          <a href="#about"><button onClick={() => { console.log("About clicked in mobile menu"); handleMenu();}}>About</button></a>
          <a href="#services"><button onClick={() => { console.log("Services clicked in mobile menu"); handleMenu();}}>Services</button></a>
          <a href="https://wa.me/447393337693?text=Hello%20BeeSimpleDesign">
            <button onClick={handleMenu}>Contact us</button>
          </a>
        </div>
      )}
    </div>
  );
};
