import "./about.css";
import React from "react";

//import image
import renata from "../../img/diversos/about me.png";


export const About = () =>{
    return(
        <div className="Main-about">
            <div className="Left-about">
                <h2 className="Title-about">About Me</h2>
                <img src={renata} alt="Renata"></img>
                <h3>Renata Rossetti Leandro</h3>
                <p className="Mobile-show">Hello! I'm Renata, a Graphic Designer and Web Developer based in London. I work with clients worldwide, bringing their visions to life with beautiful and functional websites. <br></br> <br></br>
                I have a deep interest in art and technology, and I'm always learning the latest trends to stay at the forefront of the industry. My focus is on creating user-friendly, visually appealing websites that reflect my clients' unique brands.<br></br><br></br>
                I love creating and developing, and there's nothing more satisfying than making my clients happy. When I'm not working, I enjoy exploring new art forms and design philosophies to keep my creativity flowing.<br></br><br></br>
                I'm always excited to take on new projects and help clients achieve their digital goals. Feel free to get in touch to discuss your project or ideas. I look forward to working with you!</p>
            </div>
            <div className="Right-about">
                <p>Hello! I'm Renata, a Graphic Designer and Web Developer based in London. I work with clients worldwide, bringing their visions to life with beautiful and functional websites. <br></br> <br></br>
                I have a deep interest in art and technology, and I'm always learning the latest trends to stay at the forefront of the industry. My focus is on creating user-friendly, visually appealing websites that reflect my clients' unique brands.<br></br><br></br>
                I love creating and developing, and there's nothing more satisfying than making my clients happy. When I'm not working, I enjoy exploring new art forms and design philosophies to keep my creativity flowing.<br></br><br></br>
                I'm always excited to take on new projects and help clients achieve their digital goals. Feel free to get in touch to discuss your project or ideas. I look forward to working with you!</p>
            </div>
        </div>
    )
}