const initialState = {
    services: [
        {id:1,
         title:"Layout Design",
         content:"We create custom and attractive layout designs that reflect your brand's identity. Our approach focuses on user-centric designs to ensure a seamless and engaging user experience."
        },
        {id:2,
         title:"Front-end Development",
         content:"We transform designs into interactive and responsive interfaces. Our front-end development ensures your website is visually appealing, intuitive, and functions perfectly across all devices."
        },
        {id:3,
         title:"Back-end Development",
         content:"We develop server logic and database architecture to ensure security and efficiency. Our back-end solutions are robust, scalable, and tailored to meet your specific business needs."
        },
        {id:4,
         title:"Database Integration",
         content:"We design and integrate robust databases that meet your business's specific needs. Our database solutions ensure efficient data management and quick, secure access to information."
        },
        {id:5,
         title:"Full-stack Development",
         content:"We offer complete web development services, handling all aspects of development, from front-end to back-end. Our full-stack solutions ensure a cohesive and high-performing website."
        },
    ]
}

export const servicesReducer = (state = initialState, action) => {
    return state;
};