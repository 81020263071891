import React, { useState } from "react";
import { useSelector } from 'react-redux';
import "./services.css";

// Import fotos
import imagem from "../../img/diversos/Ativo 5.png";
import plus from "../../img/diversos/+.png";
import close from "../../img/diversos/x.png";

export const Services = () => {
  const services = useSelector(state => state.services.services);
  const [expandedServiceId, setExpandedServiceId] = useState(null);

  const handleShowMore = (id) => {
    if (expandedServiceId === id) {
      setExpandedServiceId(null); 
    } else {
      setExpandedServiceId(id); 
    }
  };

  const icon = (id) => {
    return <img src={expandedServiceId === id ? close : plus} alt={expandedServiceId === id ? "less info" : "more info"} />;
  };

  return (
    <div className="Main-Services">
      <div className="Left-services">
        <img src={imagem} alt="computer" />
      </div>
      <div className="Right-service">
        <h2 className="Title-serv">Services</h2>
        {services.map((serv) => {
          return (
            <div className="Container" key={serv.id}>
              <div className="Header-info">
                <h4>{serv.title}</h4>
                <button onClick={() => handleShowMore(serv.id)}>{icon(serv.id)}</button>
              </div>
              <div className={`Content ${expandedServiceId === serv.id ? 'show' : ''}`}>
                {expandedServiceId === serv.id && (
                  <div className="More-info">
                    <p>{serv.content}</p> 
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
