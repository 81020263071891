import React from "react";
import "./hero.css";
import hero from "../../img/hero section/Ativo 1.png";

export const Hero = () => {
  return (
    <div className="Main-hero">
      <div className="Left">
        <h1>Bee<span className="Thin">SimpleDesign</span></h1>
        <h2>Transforming ideas into <br />Digital Experiences</h2>
        <a href="mailto:beesimpledesign@gmail.com"><button>Contact us</button></a>
      </div>
      <div className="Right">
        <img src={hero} alt="herosection" />
      </div>
    </div>
  );
};
