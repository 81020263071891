import React from "react";
import "./secondsection.css";

// Import images
import hivegray from "../../img/diversos/hivegray.png";
import hiveyellow from "../../img/diversos/hiveyellow.png";

export const Secondsection = () => {
  return (
    <div className="Main-secondsection">
      <h2 className="Title-background">Why Choose BeeSimpleDesign?</h2>
      <div className="Hive">
        <div className="HiveOne">
            <div className="HiveOneTitle">
                <img src={hivegray} alt="background" />
                <h3>Passion for design</h3>
            </div>
            <div className="HiveTwoTitle">
                <img src={hiveyellow} alt="background" />
                <h3>Customised solutions</h3>
            </div>
        </div>
        <div className="HiveTwo">
            <div className="HiveOneTitle">
                <img src={hivegray} alt="background" />
                <h3>Focus on simplicity and efficiency</h3>
            </div>
        </div>
        <div className="HiveThree">
            <div className="HiveOneTitle">
                <img src={hivegray} alt="background" />
                <h3>Commitment to quality</h3>
            </div>
            <div className="HiveTwoTitle">
                <img src={hiveyellow} alt="background" />
                <h3>Technology</h3>
            </div>
        </div>
      </div>
    </div>
  );
};
