import './App.css';

// store - redux
import { Provider } from 'react-redux';
import { store } from "./app/store";

// import feature
import { Header } from './feature/header/header';
import { Hero } from './feature/hero/hero';
import { Secondsection } from './feature/secondsection/secondsection';
import { Banner } from './feature/banner/banner';
import { Services } from './feature/services/services';
import { About } from './feature/about/about';
import { Footer } from "./feature/footer/footer";

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <Header />
        <Hero />
        <Secondsection />
        <Banner />
        <div id="services">
          <Services />
        </div>
        <div id="about">
          <About />
        </div>
        <Footer />
      </div>
    </Provider>
  );
}

export default App;
