import React from "react";
import "./banner.css";


export const Banner = () => {
    return(
        <div className="Main-banner">
            <h2>Contact us and let's transform your ideas into exceptional digital experiences!</h2>
        </div>
    )
}
